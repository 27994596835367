@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'inter';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'interMedium';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'interSemiBold';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'PPMonumentLight';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/PPMonumentExtended-Light.otf');
}

@font-face {
	font-family: 'PPMonumentBlack';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/PPMonumentExtended-Black.otf');
}

@font-face {
	font-family: 'PPMonument';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/PPMonumentExtended-Regular.otf');
}

@font-face {
	font-family: 'MonumentUltraBold';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts//MonumentExtended-Ultrabold.otf');
}


@font-face {
	font-family: 'Aeroport Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Aeroport Regular'), url('../fonts/aeroport/Aeroport-regular-trial.woff') format('woff');
}


@font-face {
	font-family: 'Aeroport Light';
	font-style: normal;
	font-weight: normal;
	src: local('Aeroport Light'), url('../fonts/aeroport/Aeroport-light-trial.woff') format('woff');
}


@font-face {
	font-family: 'Aeroport Medium';
	font-style: normal;
	font-weight: normal;
	src: local('Aeroport Medium'), url('../fonts/aeroport/Aeroport-medium-trial.woff') format('woff');
}


@font-face {
	font-family: 'Aeroport Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Aeroport Bold'), url('../fonts/aeroport/Aeroport-bold-trial.woff') format('woff');
}

@font-face {
	font-family: 'Aeroport Black';
	font-style: normal;
	font-weight: normal;
	src: local('Aeroport Black'), url('../fonts/aeroport/Aeroport-black.woff') format('woff');
}

.aeroLight{
	font-family: 'Aeroport Light';
}

.aero{
	font-family: 'Aeroport Regular';
}

.aeroMedium{
	font-family: 'Aeroport Medium';
}

.aeroBold{
	font-family: 'Aeroport Bold';
}

.aeroBlack{
	font-family: 'Aeroport Black';
}

html {
	scroll-behavior: smooth !important;
}

html,
body,
.bodyClass {
	position: relative;
	overflow-x: hidden;
	width: 100vw !important;
	max-width: 100vw !important;
}



.inter {
	font-family: "inter";
}

.interMedium {
	font-family: "interMedium";
}

.interSemiBold {
	font-family: "interSemiBold";
}

.PPMonumentLight {
	font-family: "PPMonumentLight";
}

.PPMonument {
	font-family: "PPMonument";
}

.PPMonumentBlack {
	font-family: "PPMonumentBlack";
}



.MonumentUltraBold {
	font-family: "MonumentUltraBold";
}

::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f8e4e4d1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e10531dd;
	border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #e64a69;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

nav {
	z-index: 9999 !important;
}

.heroCenterText {
	z-index: 50 !important;
}

.contactBtn {
	width: fit-content;
	height: fit-content;
	padding: 0.6rem 2rem;
	border-radius: 10rem;
	background-color: #E10531;
	color: white;
	isolation: isolate;
	box-shadow: 0px 0px 30px 0.5px #E10531, 0px 0px 0px 10.5963px rgba(255, 255, 255, 0.07), inset 0px -3.94722px 0px rgba(0, 0, 0, 0.2), inset 0px 2.64907px 0px rgba(255, 255, 255, 0.4);
}

.webgl {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	scale: 0.8 !important;
	opacity: 0.8;
}

@media only screen and (max-width: 768px) {
	.webgl {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		scale: 1.2 !important;
		width: 100vw !important;
		opacity: 0.7;
		overflow-x: hidden !important;
	}
}

.heroSpan1 {
	line-height: 0 !important;
	margin: 0 !important;
}

.heroSpan2 {
	line-height: 0 !important;
	margin: 0 !important;
}

.scrollChevron {
	animation: scrollChevronAnimation 2s infinite ease;
}

@keyframes scrollChevronAnimation {
	0% {
		transform: translateY(0%);
	}

	50% {
		transform: translateY(90%);
	}

	100% {
		transform: translateY(0%);
	}
}

.heroLight {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: -20% !important;
	height: 50vh !important;
	width: 300vw !important;
	background: rgba(225, 5, 49, 0.33) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(200px);
}

.burgerBar {
	width: 1.7vw;
	height: 1px;
	background-color: white;
}

@media only screen and (max-width: 768px) {
	.burgerBar {
		width: 8vw;
		height: 2px;
		background-color: white;
	}

}

.desktopNavMenuItem:hover .menuItemLabel {
	filter: invert(1);
}

.desktopNavMenuItem:hover .menuItemDot {
	opacity: 1;
	transition: opacity ease-in-out 0.4s;
}

.glassBox {
	background: rgba(196, 196, 196, 0.01);
	box-shadow: inset 0px 39px 56px -36px rgba(255, 255, 255, 0.5), inset 0px 7px 11px -4px #FFFFFF, inset 0px -82px 68px -64px rgba(96, 68, 145, 0.3), inset 0px 98px 100px -48px rgba(202, 172, 255, 0.3), inset 0px 4px 18px rgba(154, 146, 210, 0.3), inset 0px 1px 40px rgba(227, 222, 255, 0.2);
	backdrop-filter: blur(50px);
}

.glassBoxLightSpot {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: -25% !important;
	height: 50% !important;
	width: 100% !important;
	background: rgba(225, 5, 49, 0.33) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.smallGlassBoxLightSpot1 {
	position: absolute !important;
	left: 10%;
	transform: translate(-50%, 0%);
	bottom: 25% !important;
	height: 20rem !important;
	width: 20rem !important;
	background: rgba(225, 5, 49, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.smallGlassBoxLightSpot2 {
	position: absolute !important;
	left: 90%;
	transform: translate(-50%, 0%);
	top: 25% !important;
	height: 20rem !important;
	width: 20rem !important;
	background: rgba(225, 5, 49, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

@media only screen and (max-width: 768px) {
	.smallGlassBoxLightSpot1 {
		position: absolute !important;
		left: 10%;
		transform: translate(-50%, 0%);
		bottom: 25% !important;
		height: 10rem !important;
		width: 10rem !important;
		background: rgba(225, 5, 49, 0.5) !important;
		border-radius: 50% !important;
		z-index: 49 !important;
		filter: blur(100px);
	}

}

#lineTest1,
#lineTest1Mobile {
	animation: tronFilter 3s ease-in-out alternate infinite;
}

#lineTest2,
#lineTest2Mobile {
	animation: tronFilter 3s ease-in-out alternate-reverse infinite;
}


#lineTest3,
#lineTest3Mobile {
	animation: tronFilter 4s ease-in-out alternate-reverse infinite;
}

#lineTest4,
#lineTest4Mobile {
	animation: tronFilter 4s ease-in-out alternate infinite;
}



@keyframes svg-shadow {
	from {
		filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #e60073) drop-shadow(0 0 20px #e60073);
	}

	to {

		filter: drop-shadow(0 0 10px 50px #fff) drop-shadow(0 0 10px 150px #e60073) drop-shadow(0 0 10px 200px #e60073);
	}
}

@keyframes tronFilter {
	0% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #e00013);
		filter: drop-shadow(-.75px 0px 6px #e03b49);
		stroke: #e03b49;
	}

	22% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #c4258a);
		filter: drop-shadow(-.75px 0px 6px #E10531);
		stroke: #E10531;
	}

	/* end blue */

	42% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #884173);
		filter: drop-shadow(-.75px 0px 6px #884173);
		stroke: #884173;
	}

	62% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #ff0071);
		filter: drop-shadow(-.75px 0px 6px #ff0071);
		stroke: #ff0071;
	}

	/* end magenta */
	82% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #E10531);
		filter: drop-shadow(-.75px 0px 6px #e3414f);
		stroke: #e3414f;
	}

	100% {
		-webkit-filter: drop-shadow(-.75px 0px 6px #e03b49);
		filter: drop-shadow(-.75px 0px 6px #e00013);
		stroke: #e00013;
	}
}

.grafanaScreen {
	box-shadow: 0 0 15px 2px #000000 !important;
	border-radius: 1rem !important;
}

.trialSection {
	background: #E10531;
	box-shadow: 0px 0px 30px 0.5px #E10531, 0px 0px 0px 10.5963px rgba(255, 255, 255, 0.07), inset 0px -7.94722px 0px rgba(0, 0, 0, 0.2), inset 0px 2.64907px 0px rgba(255, 255, 255, 0.4);
}

.trialBtn {
	width: fit-content;
	height: fit-content;
	padding: 0.8rem 2rem 0.6rem 2rem;
	border-radius: 10rem;
	background-color: #0A0013;
	color: white;
	isolation: isolate;
	box-shadow: 0px 0px 30px 0.5px #E10531, 0px 0px 0px 10.5963px rgba(255, 255, 255, 0.07), inset 0px -3.94722px 0px rgba(0, 0, 0, 0.2), inset 0px 2.64907px 0px rgba(255, 255, 255, 0.4);
}

.marquee {
	position: relative;
	width: 100vw;
	max-width: 100%;
	height: 15vw;
	overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
	.marquee {
		position: relative;
		width: 100vw;
		max-width: 100%;
		height: 35vw;
		overflow-x: hidden;
	}

	.track {
		position: absolute;
		white-space: nowrap;
		will-change: transform;
		animation: marquee 70s linear infinite;
	}
}

.track {
	position: absolute;
	white-space: nowrap;
	will-change: transform;
	animation: marquee 100s linear infinite;
}

.track:hover {
	animation: none;
}

@keyframes marquee {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-50%);
	}
}

.moodLogoCircle {
	background: linear-gradient(225deg, #007AB5 14.64%, #0046E5 85.36%);
}

.MoodglassBoxLightSpot {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: 0px !important;
	height: 10rem !important;
	width: 10rem !important;
	background: rgba(46, 53, 232, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.ClickglassBoxLightSpot {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: 0px !important;
	height: 10rem !important;
	width: 10rem !important;
	background: rgba(224, 82, 31, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.GrafanaglassBoxLightSpot {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: 0px !important;
	height: 10rem !important;
	width: 10rem !important;
	background: rgba(2, 12, 68, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.RustglassBoxLightSpot {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: 0px !important;
	height: 10rem !important;
	width: 10rem !important;
	background: rgba(249, 249, 249, 0.5) !important;
	border-radius: 50% !important;
	z-index: 49 !important;
	filter: blur(100px);
}

.mobileNav {

	position: fixed !important;
	margin-left: auto !important;
	margin-right: auto !important;
	width: 97vw !important;
	transform: translateX(1.5vw) !important;
	z-index: 999 !important;
}

.shadowedLayer {
	background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.62) 58.36%, rgba(0, 0, 0, 0.445128) 72.5%, rgba(0, 0, 0, 0.259658) 87.5%, rgba(0, 0, 0, 0) 100%) !important;
}

.timeGraphic {
	background-position: center !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-image: url('../img/time.webp') !important;
}